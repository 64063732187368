import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { confirm } from "react-confirm-box";
import { useNavigate } from "react-router-dom";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { AppContext } from "../../../utils/AppContext";
import { enums } from "../../../utils/enums";
import { auth, db } from "../../../utils/firebaseApp";
import { createNewWorkspace } from "../../../utils/sharedFunctions";
import { MemberList } from "./MemberList";
import { useContext } from "react";
import { toast } from "react-toastify";

export const WorkspaceInformation = ({ isEditing, workspace, onUpdate, onDelete }) => {
    const navigate = useNavigate();
    const { state: { workspaces }, dispatch } = useContext(AppContext);
    const handleChange = (field) => (event) => {
        onUpdate({
            ...workspace,
            [field]: event.target.value
        });
    };

    const handleDeleteWorkspace = async () => {
        try {
            const response = await confirm(
                {
                    title: `Are you sure you want to delete this workspace?`,
                    subtitle: `This action will delete all surveys, answers, and tokens in this workspace.`,
                },
                confirmDialogOptions,
            );
            if (response === false) return;
            onDelete(workspace.uid);

        } catch (error) {
            console.error("Error deleting workspace:", error);
        }
    };

    const handleMembersUpdate = async (newMemberEmail, role) => {
        console.log('adding user', newMemberEmail, role);
        try {
            // First check if user exists in users collection
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("email", "==", newMemberEmail));
            const querySnapshot = await getDocs(q);

            let newUserId;

            if (querySnapshot.empty) {
                try {// User doesn't exist, create new user
                    const tempPassword = generateSecurePassword();
                    const userCredential = await createUserWithEmailAndPassword(auth, newMemberEmail, tempPassword);
                    newUserId = userCredential.user.uid;

                    const userObject = {
                        uid: newUserId,
                        email: newMemberEmail,
                        joined: Date.now(),
                        displayName: null,
                        photoURL: null,
                    }

                    // Create user document in Firestore
                    await setDoc(doc(db, "users", newUserId), userObject);

                    const newWorkspaceUid = crypto.randomUUID();
                    const newWorkspace = createNewWorkspace(newWorkspaceUid, userObject, enums.FREE, "My first workspace");
                    await setDoc(doc(db, "workspaces", newWorkspaceUid), newWorkspace);
                    dispatch({
                        workspaces: [...workspaces, newWorkspace]
                    });
                    toast.success("User added to workspace");
                } catch (error) {
                    toast.error("Error creating new user:", error);
                    console.error("Error creating new user:", error);
                }
            }

            // Update workspace members
            const updatedMembers = [
                ...workspace.members,
                { uid: newUserId, role }
            ];

            // Update workspace in state
            onUpdate({
                ...workspace,
                members: updatedMembers
            });

        } catch (error) {
            console.error("Error adding member:", error);
            // Handle specific error cases
        }
    };

    return (
        <Stack direction="column" spacing={5}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <IconButton
                    onClick={() => navigate('/workspaces')}
                    sx={{
                        color: 'text.primary',
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        }
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>
                    Workspaces
                </Typography>
            </Stack>

            <div>
                <TextField
                    value={workspace.name}
                    size="large"
                    sx={{
                        color: 'text.primary',
                        width: '500px',
                        '& .MuiInputBase-input': {
                            fontSize: 20
                        }
                    }}
                    onChange={handleChange('name')}
                    fullWidth
                    variant="standard"
                />
            </div>

            <div>

                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Usage</Typography>
                <Typography variant="body1" >
                    {workspace.members.length} / {enums.MAX_FREE_WORKSPACE_MEMBERS[workspace.plan]} collaborators
                </Typography>
            </div>
            <MemberList
                workspace={workspace}
                members={workspace.members}
                onAddMember={handleMembersUpdate}
                onUpdateMember={(memberId, newRole) => {
                    const updatedMembers = workspace.members.map(member =>
                        member.uid === memberId ? { ...member, role: newRole } : member
                    );
                    onUpdate({
                        ...workspace,
                        members: updatedMembers
                    });
                }}
                onRemoveMember={(memberId) => {
                    const updatedMembers = workspace.members.filter(member =>
                        member.uid !== memberId
                    );
                    onUpdate({
                        ...workspace,
                        members: updatedMembers
                    });
                }}
            />
            <div>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Plan</Typography>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>This workspace is on the {workspace.plan} plan.
                    {workspace.plan === enums.FREE ? 'You can upgrade to a paid plan to add more collaborators.' : ''}</Typography>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2, width: '200px' }}
                    onClick={() => navigate(`/workspace-plan/${workspace.uid}`)}
                >
                    Change plan
                </Button>
            </div>

            <div>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Management</Typography>
                <Button variant="contained" size="small" sx={{ mt: 2, width: '200px' }} color="error" onClick={handleDeleteWorkspace}>Delete Workspace</Button>
            </div>
        </Stack>
    );
};

export const generateSecurePassword = () => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*';

    const allChars = lowercase + uppercase + numbers + symbols;

    // Ensure at least one of each type
    let password =
        lowercase[Math.floor(Math.random() * lowercase.length)] +
        uppercase[Math.floor(Math.random() * uppercase.length)] +
        numbers[Math.floor(Math.random() * numbers.length)] +
        symbols[Math.floor(Math.random() * symbols.length)];

    // Add 8 more random characters
    for (let i = 0; i < 8; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the password
    return password.split('').sort(() => Math.random() - 0.5).join('');
}; 