import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../utils/firebaseApp';
import { CreateWorkspaceState } from '../atoms';

export const useSaveWorkspace = () => {
  const [workspace, setWorkspace] = useAtom(CreateWorkspaceState);

  const saveWorkspace = useCallback(async (workspaceData) => {
    if (!workspaceData?.uid) return;
    
    try {
      console.log('Workspace saved successfully');
      await setDoc(doc(db, 'workspaces', workspaceData.uid), workspaceData);
      console.log('Workspace saved successfully');
    } catch (error) {
      console.error('Error saving workspace:', error);
    }
  }, []);

  useEffect(() => {
    if (workspace?.uid) {
      saveWorkspace(workspace);
    }
  }, [workspace, saveWorkspace]);

  return { workspace, setWorkspace };
}; 