import React, { createContext, useEffect, useReducer } from 'react'
import { enums } from "./enums";
import { getBlankSurveyObject } from "./sharedFunctions";
import { useAuthContext } from "../contexts/auth_context";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "./firebaseApp";

export const AppContext = createContext({});

let startMessageStack = [
  {
    "role": "system",
    "content": "You are a world expert on creating surveys and surveying people on every topic. You are to help Ella design the best possible survey for the given task."
  },
  {
    "role": "system",
    "content": "you have the following question types at your disposal: " + Object.keys(enums)
  }, {
    "role": "system",
    "content": "when answering the question 'can you provide me with a list of questions?' always provide a question type that's best suited for the question you're giving from the list you've been given"
  },
]

const initialState = {
  viewToShow: enums.DASHBOARD,
  surveyObject: getBlankSurveyObject('auth.currentUser.uid'),
  surveyObjectToDisplay: null,
  surveys: [],
  questionObjects: [],
  answerObjects: [],
  questionObjectToEdit: null,
  newSurvey: 0,
  tokens: [],
  reports: [],
  messages: startMessageStack,
  surveyAnswerObjects: [],
  workspaceId: null,
}



const reducer = (state, action) => {
  return { ...state, ...action }
};

export const AppContextProvider = props => {
  const { user } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;

    const fetchSurveys = (workspaceIds) => {
      console.log("fetching surveys for workspaces:", workspaceIds)
      const surveysQuery = query(
        collection(db, "surveys"), 
        where("workspaceId", "in", workspaceIds)
      );

      return onSnapshot(surveysQuery, (querySnapshot) => {
        const newSurveys = [];
        querySnapshot.forEach(doc => {
          newSurveys.push({ ...doc.data(), id: doc.id });
        });

        dispatch({
          surveys: newSurveys.sort((a, b) => b.dateCreated - a.dateCreated)
        });
      });
    };

    const fetchWorkspaces = () => {
      console.log("fetching workspaces")
      const workspacesQuery = query(
        collection(db, "workspaces"),
        where("members", "array-contains-any", [
          { uid: user.uid, role: enums.ADMIN },
          { uid: user.uid, role: enums.EDITOR },
          { uid: user.uid, role: enums.VIEWER }
        ])
      );

      return onSnapshot(workspacesQuery, (querySnapshot) => {
        const newWorkspaces = [];
        querySnapshot.forEach(doc => {
          newWorkspaces.push({ ...doc.data(), id: doc.id });
        });
        
        const oldestWorkspace = newWorkspaces.sort((a, b) => a.createTime - b.createTime)[0];
        dispatch({
          workspaces: newWorkspaces,
          workspaceId: oldestWorkspace?.uid
        });

        // Get workspaceIds and set up surveys listener
        const workspaceIds = newWorkspaces.map(workspace => workspace.uid);
        if (workspaceIds.length > 0) {
          if (unsubscribeSurveys) unsubscribeSurveys();
          unsubscribeSurveys = fetchSurveys(workspaceIds);
        } else {
          dispatch({ surveys: [] });
        }
      });
    };

    dispatch({ currentUser: user });

    let unsubscribeSurveys;
    const unsubscribeWorkspaces = fetchWorkspaces();

    return () => {
      if (unsubscribeSurveys) unsubscribeSurveys();
      unsubscribeWorkspaces();
    };
  }, [user?.uid]);

  useEffect(() => {
    const fetchQuestions = (surveys) => {
      const questionsQuery = query(
        collection(db, "questions"),
        where("surveyUid", "in", surveys),
      );
      return onSnapshot(questionsQuery, (querySnapshot) => {
        let newQuestions = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newQuestions.push(data);
        });
        console.log("questions", newQuestions)
        dispatch({ questionObjects: newQuestions?.sort((a, b) => +b.orderNumber - +a.orderNumber) });
      });
    }
    const fetchReports = (surveys) => {
      const reportsQuery = query(
        collection(db, "reports"),
        where("surveyUid", "in", surveys),
      );
      return onSnapshot(reportsQuery, (querySnapshot) => {
        let newReports = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newReports.push(data);
        });
        console.log("reports", newReports)
        dispatch({ reports: newReports?.sort((a, b) => b.dateCreated - a.dateCreated) });
      });
    }
    const fetchAnswers = (surveys) => {
      const answerQuery = query(
        collection(db, "answers"),
        where("surveyUid", "in", surveys),
        orderBy("timestamp"),
      );
      return onSnapshot(answerQuery, (querySnapshot) => {
        let newAnswers = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newAnswers.push(data);
        });
        console.log("answers", newAnswers)
        dispatch({ answerObjects: newAnswers });
      });
    }
    if (state?.surveys?.length) {
      const surveysUid = state?.surveys?.map(survey => survey?.uid) || [];
      const questionUnsubscribe = fetchQuestions(surveysUid);
      const reportsUnsubscribe = fetchReports(surveysUid);
      const answersUnsubscribe = fetchAnswers(surveysUid);

      return () => {
        questionUnsubscribe();
        reportsUnsubscribe();
        answersUnsubscribe();
      }
    }
  }, [state?.surveys])


  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  )
};
