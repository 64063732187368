import { atom } from 'jotai';

export const initialWorkspace = {
  uid: '',
  name: '',
  description: '',
  createTime: null,
  members: [],
  owner: '',
  settings: {
    isPublic: false,
  }
};

export const CreateWorkspaceState = atom(initialWorkspace); 