import { Button, Stack } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { ProjectItem } from "../../pages/projects-list-page/components/project-list-item/ProjectItem";
import { CreateSurveyBanner } from "./banner/create-survey-banner";
import { TitleDate } from "./text-formating/title-date/title-date";

const SurveyList = ({ surveys, viewAllProjects, setViewAllProjects, workspaceName }) => {
  const shortProjectsList = surveys.slice(0, 10);

  return (
    <>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        id={"survey-stack"}
      >
        <TitleDate title={workspaceName ? workspaceName + " Surveys" : "My Surveys"} />
      </Stack>

      {!surveys ||
        (surveys.length === 0 && (
          <CreateSurveyBanner />
        ))}
      <Stack
        direction={"column"}
        gap={4}
        sx={{ my: 2, width: "100%", marginTop: "30px" }}
      >
        {viewAllProjects
          ? surveys
              .sort((a, b) => b.dateCreated - a.dateCreated)
              .map((survey) => <ProjectItem key={survey.uid} survey={survey} />)
          : shortProjectsList
              .sort((a, b) => b.dateCreated - a.dateCreated)
              .map((survey, index) => (
                <ProjectItem key={survey.uid} survey={survey} />
              ))}
      </Stack>

      {surveys && surveys.length > 10 && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ width: "100%" }}
        >
          <Button
            variant={"contained"}
            color={"success"}
            sx={{
              minWidth: "200px",
              marginTop: "50px",
              bgcolor: green[500],
              "&:hover": {
                bgcolor: green[600],
              },
            }}
            onClick={() => setViewAllProjects(!viewAllProjects)}
          >
            {viewAllProjects ? "View Less" : "View More"}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default SurveyList;
