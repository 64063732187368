import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, IconButton, Stack, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import logoImg from "../../assets/logo/Ella.svg";
import { AppContext } from '../../utils/AppContext';
import { enums } from '../../utils/enums';
import { auth } from "../../utils/firebaseApp";
import { Tooltip } from "@mui/material";
import { useAuthContext } from "../../contexts/auth_context";
import { shouldDisableButton } from "../../utils/sharedFunctions";
export const Header = ({ toggleMenu, open }) => {
    const { state } = useContext(AppContext);
    const { user } = useAuthContext();
    const currentWorkspace = state.workspaces?.find(w => w.uid === state.workspaceId);

    const deduceTitle = () => {
        if (currentWorkspace?.plan === enums.UNPAID) {
            return "Upgrade to create surveys"
        }

        if (currentWorkspace?.plan === enums.FREE) {
            return "Create Survey"
        }
        return "Create Survey"
    }
    return (
        <AppBar
            position="fixed"
            color={"inherit"}
            elevation={0}
            sx={{
                zIndex: 999,
                width: "100%",
                display: "block",
                px: 0
            }}
        >
            <Toolbar
                sx={{
                    py: 0.5, pl: '8px !important',
                    width: "100%",
                    display: "block",
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ display: { xs: "none", md: 'flex' } }}
                    >
                        <img src={logoImg} alt={"Logo"} height={60} />
                    </Stack>
                    {auth.currentUser ? <Box sx={{ display: "grid" }}>
                        <Box sx={{ display: { xs: "grid", md: "none" } }}>
                            <IconButton
                                sx={{ padding: "8px 8px 0px 8px" }}
                                onClick={() => toggleMenu(!open)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Tooltip title={shouldDisableButton(currentWorkspace, state.surveys, user).message}>
                            <span>
                                <Button
                                    disabled={shouldDisableButton(currentWorkspace, state.surveys, user).disabled}
                                    component={Link}
                                    to={"/create-survey"}
                                    sx={{
                                        padding: "6px 18px",
                                        display: { xs: "none", md: "inline-flex" },
                                    }}
                                    variant={"contained"}
                                    className={"gradient-btn"}
                                >
                                    <AddIcon sx={{ height: "18px" }} />
                                    <Typography sx={{ fontSize: "1em" }}>Create Survey</Typography>
                                </Button>
                            </span>
                        </Tooltip>
                    </Box> : null}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};


Header.propTypes = {
    toggleMenu: PropTypes.func,
    open: PropTypes.bool,
};

