import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import SurveyList from "../../components/shared-components/SurveyList";
import { TitleDate } from "../../components/shared-components/text-formating/title-date/title-date";
import { AppContext } from "../../utils/AppContext";
import { countDistinctObjectsByDay } from "../../utils/sharedFunctions";
import { TotalParticipantChart } from "../project-summaries-page/components/TotalParticipantChart";
export const DashboardPage = () => {
  const { state: { answerObjects, surveys, workspaces, workspaceId } } = useContext(AppContext);

  const [viewAllProjects, setViewAllProjects] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("");
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  
  useEffect(() => {
    if (workspaces && workspaceId) {
      const currentWorkspace = workspaces.find(w => w.uid === workspaceId);
      setFilteredSurveys(surveys.filter(survey => survey.workspaceId === workspaceId));
      setWorkspaceName(currentWorkspace?.name);
    }
  }, [workspaces, workspaceId]);

  return (
    <>
      <Stack
        maxWidth={"lg"}
        direction={"column"} gap={3}
        sx={{ pb: "100px", width: "100%", mx: { xs: 2, md: "auto" } }}
      >
        <Box sx={{ pb: 5 }}>
          <TitleDate title={workspaceName ? workspaceName : "Snapshot"} date={Date.now()} big={true} />
          <Typography variant={"h4"} sx={{ py: 2 }}>
            Total Participants Over Time
          </Typography>
          <TotalParticipantChart
            xAxisLabels={Object.keys(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
            xAxisData={Object.values(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
          />
        </Box>
        <Box>
          <SurveyList
            workspaceName={workspaceName}
            surveys={filteredSurveys}
            viewAllProjects={viewAllProjects}
            setViewAllProjects={setViewAllProjects}
          />
        </Box>

      </Stack>
    </>
  );
};

DashboardPage.propTypes = {
  projectsList: PropTypes.any,
};
