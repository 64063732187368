import { Stack } from "@mui/material";
import { deleteDoc, doc } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../contexts/auth_context";
import { AppContext } from "../../utils/AppContext";
import { db } from "../../utils/firebaseApp";
import { deleteSurvey } from "../../utils/sharedFunctions";
import { WorkspaceInformation } from "./components/workspace-information";
import { useSaveWorkspace } from "./hooks/useSaveWorkspace";
export const WorkspacePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { workspace, setWorkspace } = useSaveWorkspace();
  const { user } = useAuthContext();
  const { state: { workspaces, surveys, answerObjects, questionObjects, reports }, dispatch } = useContext(AppContext);

  useEffect(() => {
    const loadExistingWorkspace = () => {
      const workspaceData = workspaces.find(workspace => workspace.uid === id);
      setWorkspace(workspaceData);
    };
    if (id && user?.uid && workspaces) {
      loadExistingWorkspace();
    }
  }, [id, user?.uid]);

  if (!workspace?.uid) {
    return <Stack>Loading...</Stack>;
  }

  const deleteWorkspace = async () => {
    try {
      const surveysToDelete = surveys.filter(survey => survey.workspaceId === workspace.uid)
      await Promise.all(surveysToDelete.map(survey =>
        deleteSurvey(survey.uid, answerObjects, questionObjects, surveys, dispatch, survey, reports)
      ))
      
      try {
        await deleteWorkspaceFromFirebase(workspace.uid, user.uid);
        dispatch({ workspaces: workspaces.filter(w => w.uid !== workspace.uid) })
        toast.success("Workspace deleted successfully")
        navigate("/workspaces")
      } catch (error) {
        toast.error("Failed to delete workspace from Firebase")
        console.error(error)
      }
      
    } catch (error) {
      toast.error("Failed to delete workspace")
      console.error(error)
    }
  }

  return (
    <Stack
      direction={"column"}
      gap={2}
      maxWidth={"lg"}
      sx={{
        mx: { xs: 0, md: "auto" },
        px: 2,
        py: { xs: 2, md: 5 },
        position: "relative",
        height: '100%'
      }}
    >
      {/* <WorkspaceSettingsModal /> */}
      <WorkspaceInformation
        isEditing={!!id}
        workspace={workspace}
        onUpdate={setWorkspace}
        onDelete={deleteWorkspace}
      />
    </Stack>
  );
};

export const deleteWorkspaceFromFirebase = async (workspaceId, userId) => {
  try {
    // Delete the workspace document from workspaces collection
    await deleteDoc(doc(db, "workspaces", workspaceId));

    return true;
  } catch (error) {
    console.error("Error deleting workspace:", error);
    throw error;
  }
};
