import { DeleteOutline, DriveFileRenameOutline, InsertLink, QrCodeScanner, } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { confirm } from "react-confirm-box";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewIcon from "../../../../assets/icons/view-survey.png";
import { confirmDialogOptions } from "../../../../components/ConfirmDialog";
import { ShareQrCodeModal } from "../../../../components/survey-qrcode/ShareQrCodeModal";
import { useAuthContext } from "../../../../contexts/auth_context";
import { AppContext } from "../../../../utils/AppContext";
import { deleteSurvey, shouldDisableButton } from "../../../../utils/sharedFunctions";
export function ShowIcon({ iconPath, width = "28px", height = "28px" }) {
    return (
        <img
            src={iconPath}
            alt="icon"
            style={{ width, height }} // Correct usage here
        />
    );
}

export const SurveyActionButtons = ({ survey, showSettings }) => {
    const { state: { surveys, answerObjects, questionObjects, reports, workspaceId, workspaces }, dispatch} = useContext(AppContext);
    const { user } = useAuthContext();
    const [openSurveyQrCode, setOpenSurveyQrCode] = useState();
    const [workspace, setWorkspace] = useState(null);
    useEffect(() => {
        const workspace = workspaces.find(w => w.uid === workspaceId);
        setWorkspace(workspace);

    }, [workspace, surveys, user])

    const url = useMemo(() => {
        if (survey)
            return `${window.location.origin}/survey/${survey?.uid}`;
        return null;
    }, [survey]);

    const copyToClipboard = (url) => {
        try {
            navigator.clipboard.writeText(url);
            toast.success("URL copied successfully");
        } catch (error) {
            toast.success("Error copying URL");
        }
    };

    const handleDeleteSurvey = async () => {
        const response = await confirm(
            {
                title: `Are you sure you want to delete this survey?`,
                subtitle: `This action will delete everything you have pertaining to this survey.`,
            },
            confirmDialogOptions,
        );
        if (response === false) {
            return;
        }
        try {
            await deleteSurvey(
                survey.uid,
                answerObjects,
                questionObjects,
                surveys,
                dispatch,
                survey,
                reports
            );
            toast.success("Survey deleted successfully")
        } catch (error) {
            toast.error("Error deleting survey")
        }
    }
    console.log(workspace, surveys, user)
    const disabled = shouldDisableButton(workspace, surveys, user).disabled;
    const message = shouldDisableButton(workspace, surveys, user).message;
    console.log(disabled, message);
    return (
        <>
            <ShareQrCodeModal
                open={!!openSurveyQrCode}
                handleClose={() => setOpenSurveyQrCode(false)}
                urlToDisplay={url}
                title={survey.surveyName}
                subtitle={"By sharing this link you're sharing it with the public"}
            />
            <Tooltip title={"View Survey"}>
                <Button sx={primaryStyle} component={"a"} href={url} target={"_blank"}>
                    <ShowIcon iconPath={ViewIcon} />
                </Button>
            </Tooltip>
            <Tooltip title={"Copy link to survey"}>
                <Button sx={primaryStyle} onClick={() => copyToClipboard(url)}>
                    <InsertLink sx={{ transform: `rotateY(0deg) rotate(-45deg)` }} />
                </Button>
            </Tooltip>
            <Tooltip title={"View and download QR code"}>
                <Button sx={primaryStyle} onClick={() => setOpenSurveyQrCode(true)}>
                    <QrCodeScanner />
                </Button>
            </Tooltip>
            <Tooltip title={"Edit survey"}>
                <Button
                    component={Link}
                    to={`/create-survey/${survey?.uid}`}
                    disabled={!survey?.uid || shouldDisableButton(workspace, surveys, user).disabled}
                    sx={primaryStyle}
                >
                    <DriveFileRenameOutline />
                </Button>
            </Tooltip>
            <Tooltip title={"Delete survey"}>
                <Button
                    survey-uid={survey?.uid}
                    sx={{ ...secondaryStyle, bgcolor: "none", borderWidth: 0 }}
                    onClick={handleDeleteSurvey}
                    disabled={shouldDisableButton(workspace, surveys, user).disabled}
                >
                    <DeleteOutline />
                </Button>
            </Tooltip>
        </>
    );
};

const primaryStyle = {
    bgcolor: blue[50],
    color: blue[300],
    borderRadius: 2,
    border: 1,
    borderColor: blue[100],
    width: "auto !important",
    minWidth: "auto !important",
    "&:hover": {
        bgcolor: blue[100],
        color: blue[400],
    },
};
const secondaryStyle = {
    bgcolor: grey[200],
    color: red[500],
    border: 1,
    borderColor: grey[300],
    borderRadius: 2,
    width: "auto !important",
    minWidth: "auto !important",
    "&:hover": {
        // bgcolor: grey[300],
        color: red[600],
    },
};
