import { grey } from "@mui/material/colors";
import {
  Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography
} from "@mui/material";
import {
  Add, ChevronRight, DeleteOutline, DriveFileRenameOutline, ExpandMore, QrCodeScanner
} from "@mui/icons-material";
import moment from "moment";
import React, { useState } from "react";
import { ShareQrCodeModal } from "../../components/survey-qrcode/ShareQrCodeModal";
import { IconDotsVertical } from "@tabler/icons-react";
import { AppContext } from "../../utils/AppContext";
import { useEffect, useContext } from "react";
import { shouldDisableButton } from "../../utils/sharedFunctions";
import { useAuthContext } from "../../contexts/auth_context";
export const SurveyReportItem = ({
  survey,
  deleteReport,
  onCreateReport,
  onEditReport
}) => {
  const [open, setOpen] = useState(false);
  const [shareReport, setShareReport] = useState(null);
  const { state: { surveys, workspaceId, workspaces }, dispatch } = useContext(AppContext);
  const [workspace, setWorkspace] = useState(null);
  const { user } = useAuthContext();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    const workspace = workspaces.find(w => w.uid === workspaceId);
    setWorkspace(workspace);
    setDisabled(shouldDisableButton(workspace, surveys, user).disabled);
  }, [workspaceId, workspaces])

  return (
    <>
      <ShareQrCodeModal
        open={!!shareReport}
        handleClose={() => setShareReport(null)}
        urlToDisplay={`${window.location.origin}/report/${shareReport?.uid}`}
        title={shareReport?.name}
        subtitle={"By sharing this QR code/link, you are sharing the report with the public."}
      />
      <TableRow key={survey?.uid} hover sx={{ bgcolor: survey?.archived ? grey[100] : "transparent" }}>
        <TableCell sx={{ textAlign: 'center' }}>
          <IconButton sx={{ mx: "auto" }} color={"primary"} onClick={() => setOpen(prev => !prev)}
            disabled={survey?.reports?.length === 0}>
            {open ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{ overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis" }}>{survey?.surveyName}</TableCell>
        <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
          <Tooltip title={survey?.languages?.map(language => language?.Native)?.splice(0, 3)?.join(", ")}>
            <Typography>{survey?.languages?.map(language => language?.Native)?.splice(0, 2)?.join(", ") || "N/A"}{survey?.languages?.length >= 3 && "..."}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={{
          display: {
            xs: "none",
            md: "table-cell"
          }
        }}>{moment(survey?.dateCreated).format("DD MMM YYYY HH:mm")}</TableCell>
        <TableCell>
          <Button
            className={"gradient-outlined-btn"} sx={{ border: 1, px: 2 }}
            onClick={() => onCreateReport()}
            disabled={disabled}
          >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Add />
              <Typography sx={{ display: { xs: "none", md: "flex" }, color: disabled ? "gray" : "inherit" }}>
                {disabled ? "Insufficient permissions" : "Create Report"}
              </Typography>
            </Stack>
          </Button>
        </TableCell>
        {/*<TableCell>{<Chip color={survey?.archived ? "error" : "success"} label={survey?.archived ? "Not Active" : "Active"} />}</TableCell>*/}
      </TableRow>
      {open && survey?.reports?.map((report, index) => {
        return (
          <TableRow key={report?.uid}>
            <TableCell sx={{ color: grey[800], textAlign: "center" }}>
              {index + 1}.
              {/*<InsightsOutlined/>*/}
            </TableCell>
            <TableCell>{report?.name}</TableCell>
            <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>Total
              Questions: {report?.questions?.length}</TableCell>
            <TableCell sx={{
              display: {
                xs: "none",
                md: "table-cell"
              }
            }}>{moment(report?.dateCreated).format("DD MMM YYYY HH:mm")}</TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              <ReportButtons report={report} setShareReport={setShareReport} onEditReport={onEditReport}
                deleteReport={deleteReport} />
            </TableCell>
          </TableRow>
        )
      })}
    </>

  )
}


export const ReportButtons = ({ report, setShareReport, onEditReport, deleteReport }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction={"row"} gap={1} sx={{ display: { xs: "none", md: "flex" } }}>
        <Tooltip title={"View and Share Report"}>
          <IconButton
            color={"primary"}
            onClick={() => setShareReport(report)}
          >
            <QrCodeScanner />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Edit Report"}>
          <IconButton onClick={() => onEditReport(report)}>
            <DriveFileRenameOutline />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete Report"}>
          <IconButton onClick={() => deleteReport(report.uid)}>
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      </Stack>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <IconDotsVertical />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          horizontal: "right"
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom"
        }}
        PaperProps={{
          sx: { px: 2, py: 1, display: { xs: "flex", md: "none" } }
        }}
      >
        <MenuItem
          onClick={() => {
            setShareReport(report);
            handleClose();
          }}
        >
          <ListItemIcon><QrCodeScanner /></ListItemIcon>
          <ListItemText primary={"Share Report"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditReport(report)
            handleClose();
          }}
        >
          <ListItemIcon><DriveFileRenameOutline /></ListItemIcon>
          <ListItemText primary={"Edit Report"} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteReport(report.uid)
            handleClose();
          }}
        >
          <ListItemIcon><DeleteOutline /></ListItemIcon>
          <ListItemText primary={"Delete Report"} />
        </MenuItem>
      </Menu>
    </>
  )
}