import { Avatar, Button, IconButton, Stack, TextField, Typography, Select, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../utils/firebaseApp";
import { enums } from "../../../utils/enums";
export const MemberList = ({ members, onAddMember, onUpdateMember, onRemoveMember, workspace }) => {
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(enums.VIEWER);
  const [userEmails, setUserEmails] = useState([]);

  const handleAddMember = async () => {
    if (newMemberEmail && members.length < (workspace.membersPaidFor)) {
      await onAddMember(newMemberEmail, selectedRole);
      setNewMemberEmail("");
      setSelectedRole(enums.VIEWER);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, "users");
        const memberUids = members.map(member => member.uid);
        
        if (memberUids.length === 0) return;

        const q = query(usersRef, where("uid", "in", memberUids));
        const querySnapshot = await getDocs(q);
        
        const emails = [];
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.email) {
            emails.push({
              email: userData.email,
              uid: userData.uid
            });
          }
        });
        setUserEmails(emails);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [members]);

  const handleRemoveMember = (memberToRemove) => {
    onRemoveMember(memberToRemove.uid);
  };

  const handleRoleChange = (member, newRole) => {
    onUpdateMember(member.uid, newRole);
  };

  return (
    <Stack direction="column" spacing={2} sx={{ width: '100%', maxWidth: 400 }}>
      {members.map((member, index) => (
        <Stack 
          key={index} 
          direction="row" 
          spacing={2} 
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography 
            variant="body2" 
            sx={{ 
              flexGrow: 1,
              color: 'text.primary',
              py: 1,
              px: 1.5
            }}
          >
            {userEmails.find(user => user.uid === member.uid)?.email || member.email}
          </Typography>
          
          {member.role === enums.ADMIN ? (
            <TextField
              value="Admin"
              disabled
              size="small"
              sx={{ 
                width: 120,
                '& .MuiInputBase-input': {
                  py: 1,
                  px: 2
                }
              }}
            />
          ) : (
            <Select
              value={member.role || enums.VIEWER}
              size="small"
              sx={{ 
                width: 120,
                '& .MuiSelect-select': {
                  py: 1,
                  px: 2
                }
              }}
              onChange={(e) => handleRoleChange(member, e.target.value)}
            >
              <MenuItem value={enums.VIEWER} sx={{ py: 1, px: 2 }}>Viewer</MenuItem>
              <MenuItem value={enums.EDITOR} sx={{ py: 1, px: 2 }}>Editor</MenuItem>
            </Select>
          )}
          <IconButton 
            onClick={() => handleRemoveMember(member)}
            size="small"
            sx={{ color: 'text.secondary' }}
            disabled={member.role === enums.ADMIN}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}

      {members.length < enums.MAX_FREE_WORKSPACE_MEMBERS[workspace.plan] && (
        <Stack 
          direction="row" 
          spacing={2} 
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <TextField
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
            placeholder="Enter email address"
            size="small"
            variant="standard"
            sx={{ 
              marginRight: 1,  
              '& .MuiInputBase-input': {
                py: 1,
                px: 1.5
              }
            }}
          />
          <Select
            value={selectedRole}
            size="small"
            sx={{ 
              width: 120,
              '& .MuiSelect-select': {
                py: 1,
                px: 2
              }
            }}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <MenuItem value={enums.VIEWER} sx={{ py: 1, px: 2 }}>Viewer</MenuItem>
            <MenuItem value={enums.EDITOR} sx={{ py: 1, px: 2 }}>Editor</MenuItem>
          </Select>
          <IconButton 
            onClick={handleAddMember}
            size="small"
            sx={{ color: 'purple.main' }}
          >
            <PersonAddIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}; 