import { BarChart } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const ViewSummaryButton = ({ surveyUid }) => {

    return (
        <Button
            size={"large"}
            variant={"contained"}
            fullWidth
            className={"gradient-btn"}
            component={Link}
            startIcon={<BarChart />}
            sx={{ py: 1.5, mt: 1 }}
            to={`/summaries/${surveyUid}`}
        >
            View Survey Summary
        </Button>
    );
};
