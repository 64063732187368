import { Button, Stack } from "@mui/material";
import { green } from "@mui/material/colors";
import { doc, setDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { TitleDate } from "../../components/shared-components/text-formating/title-date/title-date";
import { useAuthContext } from "../../contexts/auth_context";
import { AppContext } from "../../utils/AppContext";
import { enums } from "../../utils/enums";
import { db, auth } from "../../utils/firebaseApp";
import { createNewWorkspace } from "../../utils/sharedFunctions";
import { WorkspaceItem } from "./WorkspaceItem";

export const Workspaces = () => {
    const { state: { workspaces } } = useContext(AppContext);
    const [viewAllWorkspaces, setViewAllWorkspaces] = useState(false);

async function createWorkspace() {
    const workspaceUid = crypto.randomUUID();
    const newWorkspace = createNewWorkspace(workspaceUid, auth.currentUser, enums.UNPAID, null);
    await setDoc(doc(db, "workspaces", workspaceUid), newWorkspace);
}

    return (
        <Stack direction={"column"} maxWidth={"lg"} sx={{ mx: { md: "auto" }, ml: { xs: 3, md: "auto" } }}>
            <>
                <Stack
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    id={"workspace-stack"}
                >
                    <TitleDate title="My Workspaces" />
                </Stack>


                <Stack
                    direction={"column"}
                    gap={4}
                    sx={{ my: 2, width: "100%", marginTop: "30px" }}
                >
                    {workspaces?.length > 0 && workspaces
                        .sort((a, b) => b.createTime - a.createTime)
                        .map((workspace) => (
                            <WorkspaceItem key={workspace.id} workspace={workspace} />
                        ))
                    }
                </Stack>

                {workspaces && workspaces.length > 10 && (
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        sx={{ width: "100%" }}
                    >
                        <Button
                            variant={"contained"}
                            color={"success"}
                            sx={{
                                minWidth: "200px",
                                marginTop: "50px",
                                bgcolor: green[500],
                                "&:hover": {
                                    bgcolor: green[600],
                                },
                            }}
                            onClick={() => setViewAllWorkspaces(!viewAllWorkspaces)}
                        >
                            {viewAllWorkspaces ? "View Less" : "View More"}
                        </Button>


                    </Stack>
                )}
            </>
            <Button sx={{width: "200px"}} onClick={() => createWorkspace()}>
                Create New Workspace
            </Button>
        </Stack>
    );
};
