import './new-globals.css';
import React, {Suspense, useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ConfigContext} from './contexts/config-context';
import {LoginPage} from './pages/login-page/login-page';
import {DashboardPage} from './pages/snapshot-page/dashboard-page';
import {Surveys} from './pages/projects-list-page/surveys';
import {ProjectSummariesPage} from './pages/project-summaries-page/project-summaries-page';
import {QuestionnairePage} from './pages/questionnaire-page/questionnaire-page';
import {AnalysisPage} from './pages/analysis-page/analysis-page';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import LoadingScreen from "./components/shared-components/LoadingScreen";
import {ApiPage} from "./pages/api-page/ApiPage";
import ThankYouPage from "./pages/ThankYouPage";
import SurveyDesignerPage from "./pages/survey-designer/SurveyDesignerPage";
import ReportsPage from "./pages/reports-page/ReportsPage";
import PublicReportPage from "./pages/public-report-page/PublicReportPage";
import {useAuthContext} from "./contexts/auth_context";
import {PageNotFound} from "./pages/not-found/PageNotFound";
import Survey from "./pages/survey-page-new/Survey";
import {CreateSurveyNew} from "./pages/create-project-page-new/create-survey-new";
import {MainLayout} from "./layout/main/MainLayout";
import {AccountSettingsPage} from "./pages/settings/AccountSettingsPage";
import {Workspaces} from "./pages/workspace-page/Workspaces";
import { WorkspacePage } from "./pages/workspace-page/Workspace";
import { WorkspacePlanPage } from "./pages/workspace-page/WorkspacePlan";

export const App = () => {
    const {isAuth} = useAuthContext();

    return (
        <div className='App'>
            <Suspense fallback={<LoadingScreen/>}>
                <Routes>
                    <Route path="/survey/:uid" element={<Survey/>}/>
                    {/*<Route path="/survey/:uid/preview" element={<Survey isPreview={true}/>}/>*/}
                    <Route path='/report/:uid' element={<PublicReportPage/>}/>
                    <Route path="/thankyou" index element={<ThankYouPage/>}/>

                    {isAuth === null ? <LoadingScreen/> : !isAuth ?
                        <>
                            <Route path="/login" index element={<LoginPage/>}/>
                            <Route path="*" element={<Navigate to="/login"/>}/>
                        </>
                        :
                        <Route element={<MainLayout/>}>
                            {/*<Route exact path="/" element={<MainPage/>}/>*/}
                            <Route path="/dashboard" element={<DashboardPage/>}/>
                            <Route path="/surveys" element={<Surveys/>}/>
                            <Route path="/workspaces" element={<Workspaces/>}/>
                            <Route path="/workspace/:id" element={<WorkspacePage/>}/>
                            <Route path="/workspace-plan/:id" element={<WorkspacePlanPage/>}/>
                            <Route path='/summaries' element={<ProjectSummariesPage/>}/>
                            <Route path='/summaries/:id' element={<ProjectSummariesPage/>}/>
                            <Route path='/survey-designer' element={<SurveyDesignerPage/>}/>
                            <Route exact path='/create-survey' element={<CreateSurveyNew/>}/>
                            <Route exact path='/create-survey/:id' element={<CreateSurveyNew/>}/>
                            <Route path='/analysis' element={<AnalysisPage/>}/>
                            <Route path='/analysis/:id' element={<AnalysisPage/>}/>
                            <Route path='/questionnaire-page' element={<QuestionnairePage/>}/>
                            <Route path='/reports' element={<ReportsPage/>}/>
                            <Route path='/settings' element={<AccountSettingsPage/>}/>
                            <Route path='/api/:id' element={<ApiPage/>}/>
                            <Route path='/api' element={<ApiPage/>}/>
                            <Route path="*" element={<Navigate to="/dashboard"/>}/>

                            <Route path={'/404'}
                                   element={<PageNotFound/>}/>
                        </Route>}
                </Routes>
            </Suspense>
            <ToastContainer/>
        </div>
    );
}
